import BaseSelect2AutocompleteField
  from 'js/base_v2/select2-autocomplete-field';

/**
 * Corporate Location Field.
 *
 * @class
 * @extends {BaseSelect2AutocompleteField}
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     [options]
 */
function CorporateLocationField(fieldCt, selectEl, options) {
  BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entitiesName: 'corporate_locations',
      showVendorId: false,
      select2: {
        placeholder: 'Select a Location',
        allowClear: false,
        ajax: {
          url: '/corporate/location/autocomplete',
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.getRecordLabel = function(record) {
    if (_.isEmpty(record.name)) {
      return parent.getRecordLabel.call(this, record);
    }

    let recordLabel = `${record.name}`;

    if (this.options.showVendorId && !_.isEmpty(record.vendor_id)) {
      recordLabel += ` (${record.vendor_id})`;
    }

    return recordLabel;
  };

  // Initialize
  this.init();
}

export default CorporateLocationField;
