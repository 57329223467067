import BaseFormGroup from 'js/base_v2/form-group';
import Renderer from 'js/components/renderer';

/**
 * Base Project Bom Form Group.
 *
 * @class
 * @abstract
 * @extends BaseFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function BaseProjectBomFormGroup(records, options) {
  BaseFormGroup.call(this, records, options);
  const parent = this.clone();
  const self = this;

  /**
     * @prop {?string}
     */
  this.mainFormName = null;

  /**
     * @inheritDoc
     */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      projectCreateForm: {
        switchButtons: [{
          btnClassName: 'switchTo',
          targetFormName: 'mainForm',
          label: Renderer.Back('Back', 'left'),
        }],
        onSwitchTo(targetName) {
          if ('mainForm' === targetName) {
            self.reload(self.mainFormName);
          }
        },
        afterSubmit(response) {
          return self.afterProjectCreateFormSubmit(response);
        },
      },
    });

    this.addMainFormsDefaultOptions();

    return this;
  };

  /**
     * @inheritDoc
     */
  this.init = function() {
    this.initFormNames();

    return parent.init.call(this);
  };

  /**
     * Initialize form names.
     *
     * @return {BaseProjectBomFormGroup}
     */
  this.initFormNames = function() {
    this.setMainFormName(this.getMainFormName());

    return this;
  };

  /**
     * Add main form default options.
     *
     * @return {BaseProjectBomFormGroup}
     */
  this.addMainFormsDefaultOptions = function() {
    return this.extendDefaultOptions(Object.createFromKey(
      this.mainFormName,
      this.getMainFormDefaultOptions(),
    ));
  };

  /**
     * Get main form default options.
     *
     * @return {object}
     */
  this.getMainFormDefaultOptions = function() {
    return {
      projectField: {
        createNew: true,
        createNewOptions: {
          attributes: {
            class: 'switchTo-projectCreateForm',
          },
          triggerClick: true,
        },
      },
    };
  };

  /**
     * Project create form after submit event handler.
     *
     * @param  {object}  response
     * @return {boolean}
     */
  this.afterProjectCreateFormSubmit = function(response) {
    const saveForm = this.forms[this.mainFormName];

    saveForm.extendOptions({
      projectField: {
        selectedRecords: [response.project],
      },
    });

    this.reload(this.mainFormName);

    const { projectCreateForm } = this.forms;
    projectCreateForm.resetState();

    saveForm
      .notifySuccess(projectCreateForm.getAfterSubmitMessage());

    return false;
  };

  /**
     * Set main form name.
     *
     * @param {string} formName
     */
  this.setMainFormName = function(formName) {
    this.mainFormName = formName;
  };

  /**
     * Get main form name.
     *
     * @return {?string}
     */
  this.getMainFormName = function() {
    return null;
  };
}

export default BaseProjectBomFormGroup;
