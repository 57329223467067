import BaseEntity from 'js/base_v3/models/entity';
import CorporateMarket from 'js/corporate_v2/models/market';

const staticSelf = Project;

/**
 * @const
 */
staticSelf.TYPE_PROJECT = 'project';

/**
 * @const
 */
staticSelf.TYPE_DAY_TO_DAY = 'day_to_day';

/**
 * Project Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function Project(data) {
  BaseEntity.call(this, data);

  /**
   * Get corporate market.
   *
   * @return {?CorporateMarket}
   */
  this.getVerticalMarket = function() {
    if (!(this.vertical_market_id > 0)) {
      return null;
    }

    return new CorporateMarket({
      id: this.vertical_market_id,
      name: this.vertical_market_name,
    });
  };

  // Initialize
  this._init();
}

export default Project;
