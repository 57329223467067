/* eslint-disable max-len */
import BaseProjectBomQuotationCreateForm from 'js/base_v3/forms/quotation_forms/project-bom-quotation-create-form';
import DateIntervalFieldGroup from 'js/components/date-interval-field-group';
import CloseDateField from 'js/components_v2/fields/close-date-field';
import ForeignCorporateUserField from 'js/corporate_v2/fields/foreign-corporate-user-field';
import MarketField from 'js/corporate_v2/market-field';
import { entityHelper } from 'js/helpers/entity-helper';
import Project from 'js/project_v2/models/project';
/* eslint-enable max-len */

/**
 * Base Quotation Create Form.
 *
 * @class
 * @abstract
 * @extends BaseProjectBomQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseQuotationCreateForm(record, options) {
  BaseProjectBomQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {?boolean}
   */
  this.verticalMarketRequired = null;

  /**
   * @prop {ForeignCorporateUserField}
   */
  this.contactField = null;

  /**
   * @prop {CloseDateField}
   */
  this.estimatedCloseDateField = null;

  /**
   * @prop {BaseQuotationHelper}
   */
  this.helper = null;

  /**
   * @prop {DateIntervalFieldGroup}
   */
  this.validityFieldGroup = null;

  /**
   * @prop {MarketField}
   */
  this.verticalMarketField = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: undefined,
      requiredFields: [
        'project_id',
        'job_name',
        'contact_id',
      ],
      tmplEl: '#quotation-create-form',
      tmplParams: {
        showAgreementCheckbox: false,
      },
      focusFirstNonEmptyField: true,
      contactField: {
        createNew: true,
        createNewOptions: {
          newTagLabel: this.translator.getTitle('add_new_label'),
          attributes: {
            class: 'switchTo-contactCreateFormGroup',
          },
          triggerClick: true,
        },
      },
      estimatedCloseDateFieldClass: CloseDateField,
      estimatedCloseDateField: {},
      verticalMarketFieldClass: MarketField,
      verticalMarketField: {
        select2: {
          allowClear: !this.verticalMarketRequired,
        },
      },
      validityFieldGroup: {
        sinceDateField: {
          daterangepicker: {
            locale: {
              format: 'MM/DD/YYYY',
            },
          },
        },
        untilDateField: {
          daterangepicker: {
            locale: {
              format: 'MM/DD/YYYY',
            },
          },
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.helper = this.getHelper();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    return this.extendOptions({
      tmplParams: {
        entityName: this.options.entityName,
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    // Create fields
    this
      .createContactField()
      .createField('estimatedCloseDate')
      .createField('verticalMarket');

    // Create validity field group
    this.validityFieldGroup = new DateIntervalFieldGroup(
      $('.validityFieldGroupCt', this.formEl),
      this.options.validityFieldGroup,
    ).create();

    return parent.initFields.call(this);
  };

  /**
   * Create contact field.
   *
   * @return {BaseQuotationCreateForm}
   */
  this.createContactField = function() {
    this.contactField = new ForeignCorporateUserField(
      $('.contactFieldCt', this.formEl),
      $('.contactFieldCt .contactSelect', this.formEl),
      this.options.contactField,
    ).create();

    return this;
  };

  this.onProjectFieldChange = function() {
    parent.onProjectFieldChange.call(this);

    if (!_.isObject(this.contactField)) {
      return;
    }

    const project = this.projectField.getRecord();

    if (!_.isObject(project)) {
      return;
    }

    this.contactField.extendOptions({
      autocompleteParams: {
        filters: {
          project_id: this.projectField.getRecord().id,
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.saveState = function() {
    parent.saveState.call(this);

    return this.saveFieldState('contactField');
  };

  /**
   * @inheritDoc
   */
  this.updateFieldsWithInheritedValues = function() {
    parent.updateFieldsWithInheritedValues.call(this);

    const projectData = this.projectField.getRecord();

    if (!(Object.get(projectData, 'id') > 0)) {
      return this;
    }

    const project = entityHelper.get(projectData, Project);

    const isCloseConfidenceFieldHidden = this.options.hiddenFields.includes(
      `${this.entityName}[close_confidence]`,
    );

    if (!isCloseConfidenceFieldHidden && !_.isNull(project.close_confidence)) {
      // Update close confidence field
      $('.closeConfidenceField', this.formEl).val(project.close_confidence);
    }

    const isCloseDateFieldHidden = this.options.hiddenFields.includes(
      `${this.entityName}[estimated_close_date]`,
    );
    const updateCloseDateFieldValue = !isCloseDateFieldHidden &&
      _.isObject(this.estimatedCloseDateField);

    if (updateCloseDateFieldValue && moment(project.close_date).isValid()) {
      // Update close date field
      this.estimatedCloseDateField.setValue(
        moment(project.close_date).format('MM/YYYY'),
      );
    }

    const isVerticalMarketFieldHidden = this.options.hiddenFields.includes(
      `${this.entityName}[vertical_market_id]`,
    );
    const updateVerticalMarketFieldValue = !isVerticalMarketFieldHidden &&
      _.isObject(this.verticalMarketField);

    if (updateVerticalMarketFieldValue) {
      // Update vertical market field
      this.verticalMarketField.setRecord(project.getVerticalMarket());
    }

    const isValidUntilFieldHidden = this.options.hiddenFields.includes(
      'validity_field_group',
    );

    let isProjectValidUntilDateValid = false;

    if (!isValidUntilFieldHidden && !_.isNull(project.valid_until)) {
      const projectValidUntilDate = moment(project.valid_until);

      isProjectValidUntilDateValid = projectValidUntilDate.isValid() &&
        moment(projectValidUntilDate).diff(moment()) > 0;
    }

    if (this.validityFieldGroup.getUntilDate()) {
      return this;
    }

    if (isProjectValidUntilDateValid) {
      const projectDate = moment(project.valid_until);

      // Update valid until field
      this.validityFieldGroup.fields.untilDateField.updateDatePicker({
        startDate: projectDate.format('MM/DD/YYYY'),
      });
    } else if (_.isObject(this.validityFieldGroup)) {
      const defaultDate = this.helper.getDefaultValidUntil();

      // Update valid until field
      this.validityFieldGroup.fields.untilDateField.updateDatePicker({
        startDate: defaultDate.format('MM/DD/YYYY'),
      });
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    const constraints = {};

    const requiredFields = this.options.requiredFields || [];

    _.each(requiredFields, (field) => {
      constraints[`${this.options.entityName}[${field}]`] = {
        required: {
          message: this.translator.get(`error_empty_${field}`),
        },
        skipIfDisabled: true,
        skipIfHidden: true,
      };
    }, this);

    return constraints;
  };

  /**
   * Get helper.
   *
   * @abstract
   *
   * @return {BaseQuotationHelper}
   */
  this.getHelper = null;
}

export default BaseQuotationCreateForm;
