import BaseSaveForm from 'js/base_v2/save-form';
import ProjectField from 'js/project/field';

/**
 * Base Project Bom Form.
 *
 * @class
 * @abstract
 * @extends BaseSaveForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseProjectBomForm(record, options) {
    BaseSaveForm.call(this, record, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {Bom}
     */
    this.bom = null;

    /**
     * @prop {ProjectField}
     */
    this.projectField = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            projectFieldClass: ProjectField,
            projectField: {
                select2: {
                    allowClear: false,
                    placeholder: ''
                },
                autocompleteParams: {
                    filters: {
                        access: {
                            action_type: 'edit'
                        }
                    }
                },
                onChange: function() {
                    self.onProjectFieldChange();
                }
            },
            onProjectFieldChange: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this.initFields = function() {
        parent.initFields.call(this);

        // Create fields
        this.createField('project');

        if (_.isObject(this.bom)) {
            this.getBomNameInput().val(this.bom.job_name);
        }

        // Execute project field change logic
        this.onProjectFieldChange();

        return this;
    };

    /**
     * Project field change event handler.
     *
     * @return {boolean}
     */
    this.onProjectFieldChange = function() {
        if (_.isFunction(this.options.onProjectFieldChange) &&
            false === this.options.onProjectFieldChange()
        ) {
            return false;
        }
    };

    /**
     * @inheritDoc
     */
    this.saveState = function() {
        parent.saveState.call(this);

        return this
            .saveFieldState('projectField');
    };

    /**
     * Get Bom name input.
     *
     * @return {DOMElement}
     */
    this.getBomNameInput = function() {
        return this.formEl
            .find('input[name="' + this.options.entityName + '[job_name]"]');
    };

    /**
     * @inheritDoc
     */
    this.getConstraints = function() {
        return {
            'project_id': {
                'required': {
                    'message': this.translator.get('error_empty_project')
                },
                'skipIfDisabled': true
            },
            'bom_id': {
                'required': {
                    'message': this.translator.get('error_empty_bom_name')
                },
                'skipIfDisabled': true
            }
        };
    };
}

export default BaseProjectBomForm;
