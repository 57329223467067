import BaseProjectBomFormGroup from 'js/base_v3/forms/project-bom-form-group';
import Quotation               from 'js/base_v3/models/quotation';

/**
 * Base Project Bom Quotation Create Form Group.
 *
 * @class
 * @abstract
 * @extends BaseProjectBomFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function BaseProjectBomQuotationCreateFormGroup(records, options) {
    BaseProjectBomFormGroup.call(this, records, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {?string}
     */
    this.saveCustomerQuotationFormName = null;

    /**
     * @prop {?string}
     */
    this.saveVendorQuotationFormName = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            mainFormType: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this.onSwitchToFormBtnClick = function(formName, btn) {
        if (this.saveCustomerQuotationFormName === formName ||
            this.saveVendorQuotationFormName === formName
        ) {
            this.setMainFormName(formName);
        }

        parent.onSwitchToFormBtnClick.call(this, formName, btn);
    };

    /**
     * @inheritDoc
     */
    this.initFormNames = function() {
        this.saveCustomerQuotationFormName = this.getSaveCustomerQuotationFormName();
        this.saveVendorQuotationFormName = this.getSaveVendorQuotationFormName();

        if (_.isString(this.options.mainFormType)) {
            switch (this.options.mainFormType) {
                case 'customer':
                    this.setMainFormName(this.saveCustomerQuotationFormName);
                    break;
                case 'vendor':
                    this.setMainFormName(this.saveVendorQuotationFormName);
                    break;
            }
        }

        return this;
    };

    /**
     * @inheritDoc
     */
    this.addMainFormsDefaultOptions = function() {
        this.extendDefaultOptions(Object.createFromKey(
            this.saveCustomerQuotationFormName,
            this.getSaveQuotationFormDefaultOptions(Quotation.TYPE_CUSTOMER)
        ));

        this.extendDefaultOptions(Object.createFromKey(
            this.saveVendorQuotationFormName,
            this.getSaveQuotationFormDefaultOptions(Quotation.TYPE_VENDOR)
        ));

        return this;
    };

    /**
     * Get save quotation form default options.
     *
     * @param  {string} quotationType
     * @return {object}
     */
    this.getSaveQuotationFormDefaultOptions = function(quotationType) {
        return this.getMainFormDefaultOptions();
    };

    /**
     * Get save customer quotation form name.
     *
     * @return {?string}
     */
    this.getSaveCustomerQuotationFormName = function() {
        return null;
    };

    /**
     * Get save vendor quotation form name.
     *
     * @return {?string}
     */
    this.getSaveVendorQuotationFormName = function() {
        return null;
    };
}

export default BaseProjectBomQuotationCreateFormGroup;
