import BaseEntity from 'js/base_v3/models/entity';

/**
 * Corporate Market Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function CorporateMarket(data) {
  BaseEntity.call(this, data);

  // Initialize
  this._init();
}

export default CorporateMarket;
