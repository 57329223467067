import BaseQuotation from 'js/base_v3/models/quotation';
import CorporateMarket from 'js/corporate_v2/models/market';

const staticSelf = Quote;

/**
 * @const
 */
staticSelf.TYPE_CUSTOMER = BaseQuotation.TYPE_CUSTOMER;

/**
 * @const
 */
staticSelf.TYPE_VENDOR = BaseQuotation.TYPE_VENDOR;

/**
 * @const
 */
staticSelf.STATUS_HIDDEN = 'hidden';

/**
 * @const
 */
staticSelf.STATUS_DRAFT = 'draft';

/**
 * @const
 */
staticSelf.STATUS_OPEN = 'open';

/**
 * @const
 */
staticSelf.STATUS_WON = 'won';

/**
 * @const
 */
staticSelf.STATUS_PUNCHED_OUT = 'punched_out';

/**
 * @const
 */
staticSelf.STATUS_LOST = 'lost';

/**
 * @const
 */
staticSelf.STATUS_EDITABLE_BY_CUSTOMER = 'editable_by_customer';

/**
 * Quote.
 *
 * @class
 * @extends BaseQuotation
 *
 * @param {object} [data]
 */
function Quote(data) {
  BaseQuotation.call(this, data);

  /**
   * Get corporate market.
   *
   * @return {?CorporateMarket}
   */
  this.getVerticalMarket = function() {
    if (!(this.vertical_market_id > 0)) {
      return null;
    }

    return new CorporateMarket({
      id: this.vertical_market_id,
      name: this.vertical_market_name,
    });
  };

  // Initialize
  this._init();
}

export default Quote;
