import AddressFieldGroup from 'js/address/fields/field-group';
import BaseSaveForm from 'js/base_v2/save-form';
import DateIntervalFieldGroup from 'js/components/date-interval-field-group';
import CloseDateField from 'js/components_v2/fields/close-date-field';
import MarketField from 'js/corporate_v2/market-field';

/**
 * Project Save Form.
 *
 * @class
 * @extends BaseSaveForm
 *
 * @param {?object} record
 * @param {?object} options
 */
function ProjectSaveForm(record, options) {
  BaseSaveForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {AddressFieldGroup}
   */
  this.addressFieldGroup = null;

  /**
   * @prop {MarketField}
   */
  this.verticalMarketField = null;

  /**
   * @prop {DateIntervalFieldGroup}
   */
  this.validityFieldGroup = null;

  /**
   * @prop {CloseDateField}
   */
  this.closeDateField = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'project',
      entityLabel: this.translator.get('project_label').toLowerCase(),
      actionUrl: '/project/index/save',
      tmplEl: '#project-save-form',
      addressFieldGroup: {
        allowEmptyState: true,
        stateField: {
          select2: {
            placeholder: this.translator.getTitle('label_state'),
            allowClear: true,
          },
        },
        countryField: {
          select2: {
            placeholder: this.translator.getTitle('label_country_required'),
          },
        },
      },
      verticalMarketField: {},
      validityFieldGroup: {
        sinceDateField: {
          daterangepicker: {
            locale: {
              format: 'MM/DD/YY',
            },
          },
        },
        untilDateField: {
          daterangepicker: {
            locale: {
              format: 'MM/DD/YY',
            },
          },
        },
      },
      closeDateField: {},
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    const projectData = this.getRecord();

    // Set vertical market value
    if (null !== projectData && projectData.vertical_market_id) {
      this.options.verticalMarketField.selectedRecords = [{
        id: projectData.vertical_market_id,
        name: projectData.vertical_market_name,
      }];
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    // Create address field group
    this.addressFieldGroup = new AddressFieldGroup(
      $('.addressFieldGroupCt', this.formEl),
      this.options.addressFieldGroup,
    ).create();

    // Create vertical market field
    this.verticalMarketField = new MarketField(
      $('.verticalMarketFieldCt', this.formEl),
      $('.verticalMarketFieldCt .verticalMarketSelect', this.formEl),
      this.options.verticalMarketField,
    ).create();

    // Create validity field group
    this.validityFieldGroup = new DateIntervalFieldGroup(
      $('.validityFieldGroupCt', this.formEl),
      this.options.validityFieldGroup,
    ).create();

    // Create manufacturer field
    this.closeDateField = new CloseDateField(
      $('.closeDateFieldCt', this.formEl),
      $('.closeDateFieldCt .closeDateField', this.formEl),
      this.options.closeDateField,
    ).create();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function(response) {
    if (false === parent.afterSubmit.call(this, response)) {
      return false;
    }

    window.location.href = `/bom/list?project=${response.project.id}`;

    return undefined;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    let constraints = {
      'project[name]': {
        required: {
          message: this.translator.get('error_empty_project_name'),
        },
      },
      'project[address][country]': {
        required: {
          message: this.translator.get('error_empty_address_country'),
        },
      },
    };

    if (1 === this.fieldsRequired.project_estimated_value_required) {
      constraints = {
        ...constraints,
        'project[estimated_value]': {
          required: {
            message: this.translator.get('error_empty_estimated_value'),
          },
        },
      };
    }

    if (1 === this.fieldsRequired.project_close_confidence_required) {
      constraints = {
        ...constraints,
        'project[close_confidence]': {
          required: {
            message: this.translator.get('error_empty_close_confidence'),
          },
        },
      };
    }

    if (1 === this.fieldsRequired.project_estimated_close_date_required) {
      constraints = {
        ...constraints,
        'project[close_date]': {
          required: {
            message: this.translator.get('error_empty_close_date'),
          },
        },
      };
    }

    return constraints;
  };

  // Initialize
  this.init();
}

export default ProjectSaveForm;
