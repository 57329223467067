import CorporateUserField from 'js/corporate_v2/fields/corporate-user-field';
/**
 * Foreign Corporate User Field.
 *
 * @class
 * @extends CorporateUserField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     [options]
 * @param {boolean}    [inherited]
 */
function ForeignCorporateUserField(fieldCt, selectEl, options, inherited) {
    CorporateUserField.call(this, fieldCt, selectEl, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            showCompany: true,
            select2: {
                placeholder: '',
                ajax: {
                    url: '/corporate/foreign-user/autocomplete'
                }
            }
        });
    };

    if (true !== inherited) {
        // Initialize
        this.init();
    }
}

export default ForeignCorporateUserField;
