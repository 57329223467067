import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';

export default function MarketField(fieldCt, fieldEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = $.extend(true, {}, this.defaultOptions, {
            entitiesName: 'markets',
            select2: {
                placeholder: '',
                ajax: {
                    url: '/corporate/market/autocomplete'
                }
            }
        });

        return this;
    };

    this.init();
};
