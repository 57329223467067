/* eslint-disable max-len */
import BaseQuotationRenderHelper from 'js/base_v3/helpers/quotation-render-helper';
/* eslint-enable max-len */

/**
 * Quote Render Helper.
 *
 * @class
 * @extends BaseQuotationRenderHelper
 *
 * @param {object} [options]
 */
function QuoteRenderHelper(options) {
  BaseQuotationRenderHelper.call(this, options);

  /**
   * @inheritDoc
   */
  this.getStatus = function(quote) {
    return this.getStatusFromValue(quote.status);
  };

  /**
   * @param  {string} status
   * @return {string}
   */
  this.getStatusFromValue = function(status) {
    return this._translator.getTitle(`label_quote_status_${status}`);
  };

  // Initialize
  this._init();
}

export const quoteRenderHelper = new QuoteRenderHelper();

export default QuoteRenderHelper;
