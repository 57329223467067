import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
/**
 * Project Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {?object}    options
 */
function ProjectField(fieldCt, fieldEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entitiesName: 'projects',
            autoSelectFirstOption: true,
            select2: {
                ajax: {
                    url: '/project/autocomplete'
                }
            }
        });
    };

    // Initialize
    this.init();
}

export default ProjectField;
