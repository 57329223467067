/* eslint-disable max-len */
import BaseProjectBomQuotationCreateFormGroup from 'js/base_v3/forms/quotation_forms/project-bom-quotation-create-form-group';
import BaseQuotation from 'js/base_v3/models/quotation';
import Renderer from 'js/components/renderer';
import ContactFormGroup from 'js/contacts/contact-form-group';
import ProjectSaveForm from 'js/project_v2/save-form';
/* eslint-enable max-len */

/**
 * Base Quotation Create Form Group.
 *
 * @class
 * @abstract
 * @extends BaseProjectBomQuotationCreateFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function BaseQuotationCreateFormGroup(records, options) {
  BaseProjectBomQuotationCreateFormGroup.call(this, records, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {?boolean}
   */
  this.verticalMarketRequired = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      activeFormName: 'typeChooseForm',
      typeChooseForm: {
        tmplParams: {
          disableCreateCustomerButton: !_.isFunction(
            this.getCreateCustomerQuotationFormClass(),
          ),
          disableCreateVendorButton: !_.isFunction(
            this.getCreateVendorQuotationFormClass(),
          ),
        },
      },
      contactCreateFormGroup: {
        contactSaveForm: {
          afterSubmit(response) {
            return self.afterContactSaveFormSubmit(response.contact);
          },
          switchButtons: [{
            btnClassName: 'switchTo',
            targetFormName: 'mainForm',
            label: Renderer.Back('Back', 'left'),
          }],
          onSwitchTo(targetName) {
            if ('mainForm' === targetName) {
              self.reload(self.mainFormName);
              self.forms.contactCreateFormGroup.resetStateForAllForms(
                { clearDefaultRecord: true },
              );
            }
          },
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initFormParams = function() {
    const formParams = [{
      name: 'typeChooseForm',
      className: this.getTypeChooseFormClass(),
    }, {
      name: this.saveCustomerQuotationFormName,
      className: this.getCreateCustomerQuotationFormClass(),
    }, {
      name: this.saveVendorQuotationFormName,
      className: this.getCreateVendorQuotationFormClass(),
    }, {
      name: 'projectCreateForm',
      className: ProjectSaveForm,
    }, {
      name: 'contactCreateFormGroup',
      className: ContactFormGroup,
      type: 'form_group',
    }];

    this.formParams = _.filter(
      formParams,
      (param) => _.isFunction(param.className),
    );

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onSwitchToFormBtnClick = function(formName, btn) {
    if (this.saveCustomerQuotationFormName === formName) {
      this.updateContactGroupSaveFormOptions(BaseQuotation.TYPE_CUSTOMER);
    }

    if (this.saveVendorQuotationFormName === formName) {
      this.updateContactGroupSaveFormOptions(BaseQuotation.TYPE_VENDOR);
    }

    parent.onSwitchToFormBtnClick.call(this, formName, btn);
  };

  /**
   * Update contact group save form options.
   *
   * @param {string} quotationType
   */
  this.updateContactGroupSaveFormOptions = function(quotationType) {
    const { contactCreateFormGroup } = this.forms;

    switch (quotationType) {
      case BaseQuotation.TYPE_CUSTOMER:
        contactCreateFormGroup.forms.contactGroupSaveForm.extendOptions({
          contactGroupDefaultValues: {
            is_customer: 1,
            is_vendor: 0,
            default_type: quotationType,
          },
          serverParams: {
            contact_group: {
              is_customer: 1,
            },
          },
        });
        break;
      case BaseQuotation.TYPE_VENDOR:
        contactCreateFormGroup.forms.contactGroupSaveForm.extendOptions({
          contactGroupDefaultValues: {
            is_vendor: 1,
            is_customer: 0,
            default_type: quotationType,
          },
          serverParams: {
            contact_group: {
              is_vendor: 1,
            },
          },
        });
        break;
      default:
        // Do nothing
    }
  };

  /**
   * After contact save form submit event handler.
   *
   * @param {object} contact
   */
  this.afterContactSaveFormSubmit = function(contact) {
    this.reload(this.mainFormName);

    this.forms.contactCreateFormGroup.resetStateForAllForms(
      { clearDefaultRecord: true },
    );

    this.forms[this.mainFormName].contactField.setRecord({
      id: contact.contact_corporate_user_id,
      name: contact.name,
      company_name: contact.company_name,
    });
  };

  /**
   * @inheritDoc
   */
  this.getSaveQuotationFormDefaultOptions = function(quotationType) {
    let defaultOptions = parent.getSaveQuotationFormDefaultOptions.call(
      this,
      quotationType,
    );

    if (BaseQuotation.TYPE_CUSTOMER === quotationType) {
      defaultOptions = defaultOptions.extend({
        data: {
          verticalMarketRequired: this.verticalMarketRequired,
        },
      });
    }

    return defaultOptions;
  };

  /**
   * Get type choose form class.
   *
   * @return {BaseQuoteTypeChooseForm}
   */
  this.getTypeChooseFormClass = function() {
    return undefined;
  };

  /**
   * Get create customer quotation form class.
   *
   * @return {BaseCustomerQuotationCreateForm|null}
   */
  this.getCreateCustomerQuotationFormClass = function() {
    return null;
  };

  /**
   * Get create vendor quotation form class.
   *
   * @return {BaseVendorQuotationCreateForm|null}
   */
  this.getCreateVendorQuotationFormClass = function() {
    return null;
  };
}

export default BaseQuotationCreateFormGroup;
