import BaseHelper from 'js/base_v3/helpers/helper';
import BaseQuotation from 'js/base_v3/models/quotation';

/**
 * Base Quotation Render Helper.
 *
 * @class
 * @abstract
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function BaseQuotationRenderHelper(options) {
  BaseHelper.call(this, options);

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getProjectName = function(quotation) {
    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        return quotation.vendor_project_name;
      case BaseQuotation.TYPE_VENDOR:
        return quotation.customer_project_name;
      default:
        return '';
    }
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getContactCompanyName = function(quotation) {
    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        return this.getCustomerCompanyName(quotation);
      case BaseQuotation.TYPE_VENDOR:
        return this.getVendorCompanyName(quotation);
      default:
        return '';
    }
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getContactUserName = function(quotation) {
    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        return this.getCustomerUserName(quotation);
      case BaseQuotation.TYPE_VENDOR:
        return this.getVendorUserName(quotation);
      default:
        return '';
    }
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getCustomerCompanyName = function(quotation) {
    let customerCompanyName = '';

    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        customerCompanyName = quotation.customer_contact_group_name;
        break;
      case BaseQuotation.TYPE_VENDOR:
        customerCompanyName = quotation.customer_company_name;
        break;
      default:
        // Do nothing
    }

    customerCompanyName = customerCompanyName ||
      this._translator.get('not_available_label');

    return customerCompanyName;
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getCustomerUserName = function(quotation) {
    let customerUserName = '';

    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        customerUserName = quotation.customer_contact_name;
        break;
      case BaseQuotation.TYPE_VENDOR:
        customerUserName = quotation.customer_corporate_user_name;
        break;
      default:
        // Do nothing
    }

    customerUserName = customerUserName ||
      this._translator.get('not_available_label');

    return customerUserName;
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getVendorCompanyName = function(quotation) {
    let vendorCompanyName = '';

    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        vendorCompanyName = quotation.vendor_company_name;
        break;
      case BaseQuotation.TYPE_VENDOR:
        vendorCompanyName = quotation.vendor_contact_group_name;
        break;
      default:
        // Do nothing
    }

    vendorCompanyName = vendorCompanyName ||
      this._translator.get('not_available_label');

    return vendorCompanyName;
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getVendorUserName = function(quotation) {
    let vendorUserName = '';

    switch (quotation.type) {
      case BaseQuotation.TYPE_CUSTOMER:
        vendorUserName = quotation.vendor_corporate_user_name;
        break;
      case BaseQuotation.TYPE_VENDOR:
        vendorUserName = quotation.vendor_contact_name;
        break;
      default:
        // Do nothing
    }

    vendorUserName = vendorUserName ||
      this._translator.get('not_available_label');

    return vendorUserName;
  };

  /**
   * @param  {BaseQuotation} quotation
   * @return {string}
   */
  this.getStatus = function(quotation) {
    return this._translator.getTitle(quotation.status);
  };
}

export default BaseQuotationRenderHelper;
