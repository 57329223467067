import ModalForm from 'js/base_v2/modal-form';
/**
 * Base Type Choose Form.
 *
 * @class
 * @abstract
 * @extends ModalForm
 *
 * @param {object} options
 */
function BaseTypeChooseForm(options) {
    ModalForm.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = this.defaultOptions.extend({
            entityName: '',
            tmplEl: '#quotation-type-choose-form',
            tmplParams: {
                title: 'Create',
                switchToCustomerFormName: 'customerCreateForm',
                switchToVendorFormName: 'vendorCreateForm'
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.prepareTmplParams = function() {
        parent.prepareTmplParams.call(this);

        return this.extendOptions({
            tmplParams: {
                entityName: this.options.entityName
            }
        });
    };
}

export default BaseTypeChooseForm;
