import BaseQuotationHelper from 'js/base_v3/helpers/quotation-helper';
/**
 * Quote Helper.
 *
 * @class
 * @extends BaseQuotationHelper
 *
 * @param {object} [options]
 */
function QuoteHelper(options) {
    BaseQuotationHelper.call(this, options);
    var parent = this.clone();
    var self = this;

    // Initialize
    this._init();
}

export default QuoteHelper;
