import BaseHelper from 'js/base_v3/helpers/helper';

/**
 * Base Quotation Helper.
 *
 * @class
 * @abstract
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function BaseQuotationHelper(options) {
  BaseHelper.call(this, options);

  /**
   * Get valid until from project.
   *
   * @param  {object} project
   * @return {moment}
   */
  this.getValidUntilFromProject = function(project) {
    const validUntil = moment(Object.get(project, 'valid_until'));

    if (validUntil.isValid()) {
      return validUntil;
    }

    return this.getDefaultValidUntil();
  };

  /**
   * Get default valid until date.
   *
   * @return {moment}
   */
  this.getDefaultValidUntil = function() {
    return moment().add(30, 'days');
  };
}

export default BaseQuotationHelper;
