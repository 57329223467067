import BaseProjectBomForm from 'js/base_v3/forms/project-bom-form';

/**
 * Base Project Bom Quotation Create Form.
 *
 * @class
 * @abstract
 * @extends BaseProjectBomForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseProjectBomQuotationCreateForm(record, options) {
    BaseProjectBomForm.call(this, record, options);
    var parent = this.clone();
    var self = this;

    /**
     * Project field change event handler.
     *
     * @return {boolean}
     */
    this.onProjectFieldChange = function() {
        parent.onProjectFieldChange.call(this);

        // Update fields with inherited values
        this.updateFieldsWithInheritedValues();
    };

    /**
     * Update fields with inherited values.
     *
     * @return {BaseProjectBomForm}
     */
    this.updateFieldsWithInheritedValues = function() {
        return this;
    };

    /**
     * Get submit button DOMElement.
     *
     * @return {DOMElement}
     */
    this.getSubmitButton = function() {
        return $('button[type="submit"]', this.formEl);
    };
}

export default BaseProjectBomQuotationCreateForm;
