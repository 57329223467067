/* eslint-disable max-len */
import BaseQuotationCreateForm from 'js/base_v3/forms/quotation_forms/create-form';
import BaseQuotation from 'js/base_v3/models/quotation';
/* eslint-enable max-len */

/**
 * Base Vendor Quotation Create Form.
 *
 * @class
 * @abstract
 * @extends BaseQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseVendorQuotationCreateForm(record, options) {
  BaseQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityLabel: undefined,
      actionUrl: undefined,
      tmplEl: undefined,
      tmplParams: {
        quotationType: BaseQuotation.TYPE_VENDOR,
      },
      contactField: {
        autocompleteParams: {
          filters: {
            user_type: BaseQuotation.TYPE_VENDOR,
          },
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processFormData = function(formData) {
    let processedFormData = parent.processFormData.call(this, formData);

    processedFormData = this.processValidUntilFormData(processedFormData);

    return processedFormData;
  };

  /**
   * Process valid until form data.
   *
   * @param  {object} formData
   * @return {object}
   */
  this.processValidUntilFormData = function(formData) {
    if (!_.isObject(formData[this.options.entityName])) {
      return formData;
    }

    if (_.isString(formData[this.options.entityName].valid_until) &&
      moment(formData[this.options.entityName].valid_until).isValid()
    ) {
      return formData;
    }

    const projectValidUntil = Object.get(
      this.projectField.getRecord(),
      'valid_until',
      null,
    );

    const validUntil = moment(projectValidUntil).isValid() ?
      moment(projectValidUntil) :
      moment().add(30, 'days');

    const processedFormData = formData.clone();

    processedFormData[this.options.entityName].valid_until =
      validUntil.format('MM/DD/YYYY');

    return processedFormData;
  };
}

export default BaseVendorQuotationCreateForm;
