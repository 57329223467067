/* eslint-disable max-len */
import BaseCustomerQuotationCreateForm from 'js/base_v3/forms/quotation_forms/customer-quotation-create-form';
import QuoteHelper from 'js/quote/helpers/helper';
/* eslint-enable max-len */

/**
 * Customer Quote Create Form.
 *
 * @class
 * @extends BaseCustomerQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function CustomerQuoteCreateForm(record, options) {
  BaseCustomerQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'quote',
      entityLabel: this.translator.get('customer_quote_label'),
      actionUrl: '/quote/customer/save',
      createLocationField: true,
      requiredFields: [
        'project_id',
        'job_name',
        'contact_id',
        'close_confidence',
        'estimated_close_date',
        'valid_until',
        'location_id',
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.extendOptions({
      hiddenFields: [
        `${this.options.entityName}[purchase_order_number]`,
        `${this.options.entityName}[external_customer_number]`,
        `${this.options.entityName}[external_number]`,
        `${this.options.entityName}[external_project_name]`,
        `${this.options.entityName}[revision_number]`,
        `${this.options.entityName}[attach_to]`,
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.getHelper = function() {
    return new QuoteHelper();
  };

  // Initialize
  this.init();
}

export default CustomerQuoteCreateForm;
