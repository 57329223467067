import BaseTypeChooseForm from 'js/base_v3/forms/type-choose-form';

/**
 * Quote Type Choose Form.
 *
 * @class
 * @extends BaseTypeChooseForm
 *
 * @param {object} options
 */
function QuoteTypeChooseForm(options) {
  BaseTypeChooseForm.call(this, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'quote',
      tmplParams: {
        title: 'Create a Quote',
        switchToCustomerFormName: 'customerQuoteCreateForm',
        switchToVendorFormName: 'vendorQuoteCreateForm',
      },
    });
  };

  // Initialize
  this.init();
}

export default QuoteTypeChooseForm;
