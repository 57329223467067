import Address    from 'js/address/models/address';
import BaseEntity from 'js/base_v3/models/entity';

var staticSelf = BaseQuotation;

/**
 * @const
 */
staticSelf.TYPE_CUSTOMER = 'customer';

/**
 * @const
 */
staticSelf.TYPE_VENDOR = 'vendor';

/**
 * Base Quotation.
 *
 * @class
 * @abstract
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function BaseQuotation(data) {
    BaseEntity.call(this, data);
    var parent = this.clone();
    var self = this;

    /**
     * Get ship to address.
     *
     * @return {?Address}
     */
    this.getShipToAddress = function() {
        return _.isObject(this.ship_to_address) ?
            new Address(this.ship_to_address) :
            null;
    };

    /**
     * Get sold to address.
     *
     * @return {?Address}
     */
    this.getSoldToAddress = function() {
        return _.isObject(this.sold_to_address) ?
            new Address(this.sold_to_address) :
            null;
    };

    /**
     * Get bill to address.
     *
     * @return {?Address}
     */
    this.getBillToAddress = function() {
        return _.isObject(this.bill_to_address) ?
            new Address(this.bill_to_address) :
            null;
    };
}

export default BaseQuotation;
