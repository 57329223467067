/* eslint-disable max-len */
import BaseVendorQuotationCreateForm from 'js/base_v3/forms/quotation_forms/vendor-quotation-create-form';
import QuoteHelper from 'js/quote/helpers/helper';
/* eslint-enable max-len */

/**
 * Vendor Quote Create Form.
 *
 * @class
 * @extends BaseVendorQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function VendorQuoteCreateForm(record, options) {
  BaseVendorQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'quote',
      entityLabel: this.translator.get('vendor_quote'),
      actionUrl: '/quote/vendor/save',
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.extendOptions({
      hiddenFields: [
        `${this.options.entityName}[close_confidence]`,
        `${this.options.entityName}[vertical_market_id]`,
        `${this.options.entityName}[valid_until]`,
        `${this.options.entityName}[purchase_order_number]`,
        `${this.options.entityName}[external_customer_number]`,
        `${this.options.entityName}[location_id]`,
        `${this.options.entityName}[estimated_close_date]`,
        `${this.options.entityName}[attach_to]`,
        'validity_field_group',
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.getHelper = function() {
    return new QuoteHelper();
  };

  // Initialize
  this.init();
}

export default VendorQuoteCreateForm;
