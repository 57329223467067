/* eslint-disable max-len */
import BaseQuotationCreateForm from 'js/base_v3/forms/quotation_forms/create-form';
import BaseQuotation from 'js/base_v3/models/quotation';
import CorporateLocationField from 'js/corporate_v2/fields/location-field';
/* eslint-enable max-len */

/**
 * Base Customer Quotation Create Form.
 *
 * @class
 * @abstract
 * @extends BaseQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseCustomerQuotationCreateForm(record, options) {
  BaseQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {CorporateLocationField}
   */
  this.locationField = null;

  /**
   * @prop {?CorporateLocation}
   */
  this.userLocation = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityLabel: undefined,
      actionUrl: undefined,
      tmplEl: undefined,
      tmplParams: {
        quotationType: BaseQuotation.TYPE_CUSTOMER,
      },
      contactField: {
        autocompleteParams: {
          filters: {
            user_type: BaseQuotation.TYPE_CUSTOMER,
          },
        },
        onChange: () => {
          this.onCustomerContactFieldChange();
        },
      },
      createLocationField: false,
      locationFieldClass: CorporateLocationField,
      locationField: {
        select2: {
          allowClear: false,
          placeholder: '',
        },
        selectedRecords: _.isObject(this.userLocation) ?
          [this.userLocation] :
          [],
        showVendorId: true,
      },
    });
  };

  /**
   * Customer contact field change event handler.
   */
  this.onCustomerContactFieldChange = function() {
    if (!_.isObject(this.locationField)) {
      return;
    }

    const contactData = this.contactField.getRecord();

    if (_.isEmpty(contactData.owner_location_id)) {
      return;
    }

    this.locationField.setRecord({
      id: contactData.owner_location_id,
      name: contactData.owner_location_name,
      vendor_id: contactData.owner_location_vendor_id,
    });
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    if (this.options.createLocationField) {
      this.createField('location');
    }

    return this;
  };
}

export default BaseCustomerQuotationCreateForm;
