import BaseSelect2Field from 'js/base_v2/select2-field';

/**
 * Close Date Field.
 *
 * @class
 * @extends BaseSelect2Field
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     options
 */
function CloseDateField(fieldCt, fieldEl, options) {
  BaseSelect2Field.call(this, fieldCt, fieldEl, options);

  const parent = this.clone();

  /**
   * @inheritDoc
  */
  this.initDefaults = function () {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      startDate: moment().startOf('month'),
      endDate: moment().add(12, 'months').endOf('month'),
      select2: {
        placeholder: '',
      },
    });
  };

  /**
   * @inheritDoc
  */
  this.initFields = function () {
    this.initCloseDateOptions();
    return parent.initFields.call(this);
  };

  /**
   * Initialize close date select field options.
   *
   * @return {CloseDateField}
   */
  this.initCloseDateOptions = function () {
    const currentDate = new Date(this.options.startDate);
    const endDate = new Date(this.options.endDate);

    while (currentDate <= endDate) {
      this.fieldEl.append(
        `<option value="${moment(currentDate).format('MM/YYYY')}">
          ${moment(currentDate).format('MMMM|YYYY')}
        </option>`,
      );

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return this;
  };

  // Initialize
  this.init();
}

export default CloseDateField;
