/* eslint-disable max-len */
import BaseQuotationCreateFormGroup from 'js/base_v3/forms/quotation_forms/create-form-group';
import CustomerQuoteCreateForm from 'js/quote/customer/create-form';
import QuoteTypeChooseForm from 'js/quote/type-choose-form';
import VendorQuoteCreateForm from 'js/quote/vendor/forms/create-form';
/* eslint-enable max-len */

/**
 * Quote Create Form Group.
 *
 * @class
 * @extends BaseQuotationCreateFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function QuoteCreateFormGroup(records, options) {
  BaseQuotationCreateFormGroup.call(this, records, options);

  /**
   * @inheritDoc
   */
  this.getTypeChooseFormClass = function() {
    return QuoteTypeChooseForm;
  };

  /**
   * @inheritDoc
   */
  this.getSaveCustomerQuotationFormName = function() {
    return 'customerQuoteCreateForm';
  };

  /**
   * @inheritDoc
   */
  this.getSaveVendorQuotationFormName = function() {
    return 'vendorQuoteCreateForm';
  };

  /**
   * @inheritDoc
   */
  this.getCreateCustomerQuotationFormClass = function() {
    return CustomerQuoteCreateForm;
  };

  /**
   * @inheritDoc
   */
  this.getCreateVendorQuotationFormClass = function() {
    return VendorQuoteCreateForm;
  };

  // Initialize
  this.init();
}

export default QuoteCreateFormGroup;
